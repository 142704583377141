// There is nothing to do with this class:
// it is used for all iframes on the website to be responsive by default.
// npm module: https://www.npmjs.com/package/reframe.js#noframe
const reframe = require('reframe.js/dist/reframe');

export default class Reframe {
  constructor(context) {
    reframe('.site-content iframe:not([id^="gform"])');
  }
}
